.appContainer h1 {
	text-align: center;
	margin: 20px 0;
}

.appContainer .inputTable {
	font-family: arial, sans-serif;
	border-collapse: collapse;
	width: 50%;
	margin: 0 auto;
}

.appContainer .inputTable td {
	border: none;
	text-align: left;
	padding: 5px;
	width: 48%;
}

.appContainer .inputTable tr td:nth-child(2) {
	width: 4%;
}

.appContainer .output {
	font-family: arial, sans-serif;
	border-collapse: collapse;
	width: 90%;
	margin: 0 auto;
	margin-bottom: 3rem;
}

.appContainer .output td,
.appContainer .output th {
	border: 1px solid #dddddd;
	text-align: center;
	padding: 8px;
	width: 20%;
}

.appContainer .output tr:nth-child(even) {
	background-color: #dddddd;
}

.appContainer .actionContainer {
	text-align: center;
	margin: 20px 0;
}

.appContainer .actionContainer button {
	background-color: #00c851;
	border: none;
	padding: 10px 20px;
	border-radius: 10px;
	color: #fff;
	transition: 0.7s;
	outline: none;
	cursor: pointer;
	margin: 0 10px;
}

.appContainer .actionContainer button:hover {
	background-color: #ccc;
	color: #000;
}

.appContainer .actionContainer button.resetBtn {
	background-color: red;
	border: none;
	padding: 10px 20px;
	border-radius: 10px;
	color: #fff;
	transition: 0.7s;
	outline: none;
}

.appContainer .actionContainer button:hover {
	background-color: #ccc;
	color: #000;
}

.loaderContainer {
	padding: 50px;
	min-height: 200px;
	display: flex;
    justify-content: center;
    align-items: center;
	overflow: hidden;
}

.loader,
.loader:after {
   border-radius: 50%;
   width: 5em;
   height: 5em;
}
.loader {
   	margin: 0px auto;
  	font-size: 10px;
  	position: relative;
  	text-indent: -9999em;
  	border-top: 0.8em solid rgba(255, 0, 0, 0.2);
  	border-right: 0.8em solid rgba(255, 0, 0, 0.2);
  	border-bottom: 0.8em solid rgba(255, 0, 0, 0.2);
  	border-left: 0.8em solid rgba(255, 0, 0, 0.9);
  	-webkit-transform: translateZ(0);
  	-ms-transform: translateZ(0);
  	transform: translateZ(0);
  	-webkit-animation: load8 1.1s infinite linear;
  	animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  	0% {
    	-webkit-transform: rotate(0deg);
    	transform: rotate(0deg);
  	}
  	100% {
    	-webkit-transform: rotate(360deg);
    	transform: rotate(360deg);
  	}
}
@keyframes load8 {
  	0% {
    	-webkit-transform: rotate(0deg);
    	transform: rotate(0deg);
  	}
  	100% {
    	-webkit-transform: rotate(360deg);
    	transform: rotate(360deg);
  	}
}
